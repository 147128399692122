<template>
  <div id="divVideoDetailWrapper" class="content-layout-fixer">
    <div class="detail-top">
      <div class="video-wrapper">
        <BrandVimeoPlayer
          v-if="mediaData"
          :logger="{ interval: 5 }"
          :data="mediaData"
          :id="mediaData.videoId"
          @timeUpdate="onProgress($event)"
          @end="onProgress($event)"
        ></BrandVimeoPlayer>
      </div>
    </div>

    <div class="detail-bottom">
      <VueHeadline v-if="mediaData" level="2" weightLevel="4" class="video-title">{{
        mediaData.title
      }}</VueHeadline>
    </div>
    <div class="video-content-holder">
      <VueHeadline class="training-videos-headline" level="2" weightLevel="4"
        >Eğitim Videoları</VueHeadline
      >
      <div class="video-content-wrapper">
        <div v-for="item in mediaItems" :key="item.id">
          <BrandMediaListItem
            :text="item.title"
            :percentage="item.percentWatched"
            :mediaSource="item"
            @handleRoute="handleVideoRouting(item)"
          ></BrandMediaListItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import BrandMediaListItem from '@/components/brand/BrandMediaListItem/BrandMediaListItem.vue';
import MEDIA_CONSTANTS from '@/constants/media.constants.js';
import StorageHelper from '@/utils/storageHelper';
import { Learn } from '@/services/Api/index';
import BrandVimeoPlayer from '@/components/brand/BrandVimeoPlayer/BrandVimeoPlayer.vue';
import vimeoUtils from '@/mixins/vimeoUtils.js';

export default {
  name: 'VideoDetail',
  components: {
    VueHeadline,
    BrandMediaListItem,
    BrandVimeoPlayer,
  },
  mixins: [vimeoUtils],
  data() {
    return {
      mediaData: null,
      mediaItems: [],
    };
  },
  methods: {
    onProgress(eventData) {
      let index = this.mediaItems.findIndex(x => x.videoId === this.mediaData.videoId);
      this.mediaItems[index].percentWatched = eventData.percent;
      this.mediaItems[index].playbackPosition = eventData.position;
      this.mediaItems[index].viewStatus = eventData.viewStatus;
      this.mediaItems[index].isViewed = eventData.isViewed;
    },
    handleVideoRouting(item) {
      new StorageHelper({
        id: process.env.VUE_APP_STORAGE_VIDEO_DETAIL_KEY,
      }).set(item);
      this.mediaData = null;
      setTimeout(() => {
        this.mediaData = item;
        window.scrollTo(0, 0);
      }, 200);
    },
  },
  computed: {
    mediaConstants() {
      const media = {
        aspect: MEDIA_CONSTANTS.MEDIA_ASPECT_RATIOS,
        type: MEDIA_CONSTANTS.MEDIA_CONTENT_TYPES,
      };
      return { media };
    },
  },
  created() {
    this.$route.meta.breadcrumb[2].name = this.$route.params.contentTypeName;
    this.$route.meta.breadcrumb[2].link = `digital-literacy/${this.$route.params.contentTypeId}`;
    this.$store.dispatch('app/setOverlayFunction', false);
    let selectedMedia = new StorageHelper({
      id: process.env.VUE_APP_STORAGE_VIDEO_DETAIL_KEY,
    }).get();
    if (selectedMedia && selectedMedia.levelId) {
      Learn.getContentByLevelId(selectedMedia.levelId).then(res => {
        const response = res.data.Data;
        if (response) {
          let mediaArray = response.videos
            .filter(item => item.link && item.link.length > 0)
            .map(item => ({
              videoId: item.id,
              title: item.title,
              levelId: selectedMedia.levelId,
              viewStatus: item.status,
              percentWatched: item.percent,
              playbackPosition: item.playbackPosition,
              isStarting: false,
              isViewed: item.viewCount > 0,
              embedVideo: item.link.replace(/\r?\n|\r/g, '').trim(),
            }));
          this.mediaData = mediaArray.find(x => x.videoId == selectedMedia.videoId);

          this.mediaItems = this.getVideoThumbs(mediaArray);
        }
      });
    }
  },
};
</script>

<style scoped lang="scss">
.video-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  .video-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.detail-bottom {
  border-bottom: 1px solid palette-color-level('grey', '20');

  .video-title {
    padding: palette-space-level(30) palette-space-level(20);
  }

  .training-videos-headline {
    padding: palette-space-level(20) 0;
  }
}

.video-content-holder {
  margin-top: palette-space-level(60);
  padding-left: palette-space-level(20);

  .video-content-wrapper {
    margin-top: palette-space-level(20);
    display: grid;
    grid-template-columns: repeat(3, 321px);
    grid-gap: palette-space-level(20);
  }

  .video-content-wrapper > div {
    border: 1px solid palette-color-level('grey', '20');
  }
}
</style>
